import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CONTEXT } from './config';
import BasicLayout from './layouts/BasicLayout';
const Live = React.lazy(() => import('./pages/Live/Live'));
const Help = React.lazy(() => import('./pages/Help/Help'));

function App() {
  return (
    <Router>
      <BasicLayout>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={`${CONTEXT}/live`}>
              <Live></Live>
            </Route>
            <Route exact path={`${CONTEXT}/help`}>
              <Help></Help>
            </Route>
          </Switch>
        </Suspense>
      </BasicLayout>
    </Router>
  );
}

export default App;
